@import 'assets/scss/main.scss';

.container{
    width: 100%;
    padding: 2% 5%;
    @include for_media(tabletScreen mobileScreen){
        padding: 4%;
     } 
      .titleContainer{
        h2{
            font-family: $secondaryFontName !important;
            font-size: 1.4em !important;
            text-align: center;
            color: $v2PrimaryVariant2;
        }
        .line{
            width: 80%;
            height: 3px;
            background: linear-gradient(to right, $white, $v2PrimaryVariant2, $white);
            display: inline-block;
            margin: 0em 10%;
          }
        p{
            font-family: $secondaryFontName !important;
            font-size: 1.1em !important;
            text-align: center;
            margin: 1em 0em;
        }
        @include for_media(tabletScreen mobileScreen){
            h2{
                font-size: 1.2em !important;
            }
         }   
      }
    .imageWrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2em;
        .firstContainer{
            display: flex;
            justify-content: center;
            gap: 4.3em;
            width: 100%;
            flex-wrap: wrap;
            .videoCoverFrame{
                width: 47% !important;
            }
        }

        @include for_media(tabletScreen mobileScreen){
           .firstContainer{
            display: grid;
            gap: 2em;
            svg{
                width: 100%;
                height: 100%;
            }
           }
           .secondContainer{
             width: 100%;
           }
         }   
         @include for_media(bigtabletScreen mobileScreen){
            .firstContainer{
             display: contents;
             svg{
                 width: 100%;
                 height: 100%;
             }
            }
            .secondContainer{
              width: 100%;
            }
          }   
    }
    .buttonWrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 2em 1em 2em;

        button{
            border-radius: 30px;
            padding: 0.7em 3em;
            border: 1px solid $black;
            color: $black;
            background-color: $white;
            font-family: $secondaryFontName !important;
            -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
            transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
            cursor: pointer;
        }
        
        button:hover{
            transform: translateY(-5px);
            box-shadow: 0 6px 0 0 $v2PrimaryVariant3;
        }
        @include for_media(tabletScreen mobileScreen){
            padding: 0.4em 1.5em;
            button{
                padding: 0.4em 1.5em;
                font-size: 1.5em;   
            }
        }
    }

    .redLeaf{
        position: absolute;
        bottom: -2%;
        left: -2.5%;
        img{
            width: 75%;
        }
        @include for_media(tabletScreen mobileScreen){
            position: relative;
            height: 4em;      
            img{
                width: 35%;
                position: absolute;
                bottom: -10px;
                right: 73%;
            }
        }
    }
    .dotPattern{
        position: absolute;
        top: 0%;
        left: 0;
        width: 8%;
        transform: rotate(345deg);
        @include for_media(tabletScreen mobileScreen){
            width: 20%;   
        }
    }
    .backgroundText{
        width: 100%;
        height: 5em;
        float: inline-end;
        svg{
            position: relative;
            right: -46%;
            width: 60%;
            z-index: -1;
            bottom: 40%;
            }
         
         @include for_media(tabletScreen mobileScreen){
          h1{
            font-size: 13.8vw !important;
            position: unset !important;
          }
        }
     }

}
.container{
    position: relative;
    .topBorder::before,
    .topBorder::after,
    .bottomBorder::after,
    .bottomBorder::before
    {
      content: "";
      position: absolute;
      display: inline-block;
      height: 70px;
      @include for_media(tabletScreen mobileScreen){
        height: 40px;
      }
    }
    .topBorder::before{
      background: linear-gradient(to right, $white, $v2PrimaryVariant2);
      opacity: 50%;
      width: 200px;
      right: 0;
      top: 0%;
      @include for_media(tabletScreen mobileScreen){
        width: 74px;
      }
    }
    .topBorder::after{
      background: linear-gradient(to right, $v2PrimaryVariant2, $white);
      opacity: 50%;
      width: 200px;
      right: -65px;
      top: 4.6em;
      transform: rotate(90deg);
      @include for_media(tabletScreen mobileScreen){
        width: 74px;
        right: -17px;
        top: 1.2em;
      }
    }
    .bottomBorder::before{
        background: linear-gradient(to right, $grayColor2, $white);
        width: 200px;
        left: 0;
        bottom: 0;    
        @include for_media(tabletScreen mobileScreen){
            width: 172px;
          }
    }
    .bottomBorder::after{
        background: linear-gradient(to right, $white, $grayColor2);
        width: 305px;
        left: -118px;
        bottom: 13.329em;
        transform: rotate(90deg);
        @include for_media(tabletScreen mobileScreen){
            width: 190px;
            left: -68px;
            bottom: 7.7em;
            height: 54px;
            background: linear-gradient(to right, rgba($grayColor1, 0), $grayColor3);
        }
    }
 }
 .videoContainer{
    padding-top: 43.78% !important;
    background-color: $v2PrimaryVariant2 !important;
    width: 100% !important;
 }


.hideImage {
    transform: translateX(100%);
    transition: transform 1s ease;
}
.visibleImage{
    transform: translateX(0%);
    transition: transform 1s ease;
}

.ConfluenceFrame{
    width: 47% !important;
    @include for_media(tabletScreen mobileScreen){
        width: 100% !important;
    }
}