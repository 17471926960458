@import './config.scss';

  body {
    background: url('../images/backgroundImage.webp') !important;
    line-height: 1.2 !important;
    letter-spacing: 0.015em !important;
    font-family: $primaryFontName !important;
  }


  @mixin headingStyle {
    font-family: $primaryFontName;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  @mixin for_media($breakpoints) {
    $conditions : ();
    @each $breakpoint in $breakpoints {
        // If the key exists in the map
        $conditions: append(
            $conditions,
            #{inspect(map-get($media_queries, $breakpoint))},
            comma
        );
    }

    @media #{$conditions} {
        @content;
    }
}

h1 {
  font-family: $primaryFontName !important;
  font-size: 3.5vw !important;
  font-weight: bold;
  @include for_media(mobileScreen) {
    font-size: 10vw !important;
  }
  @include for_media(tabletScreen) {
    font-size: 5.5vw !important;
  }
}


h2 {
  font-family: $primaryFontName !important;
  font-size: 2.5vw !important;
  font-weight: bold !important;

  @include for_media(mobileScreen) {
    font-size: 8vw !important;
  }

  @include for_media(tabletScreen) {
    font-size: 3.5vw !important;
  }
}


h3 {
  font-family: $primaryFontName !important;
  text-transform: uppercase !important;
  font-size: 2vw !important;
  font-weight: bold !important;

  @include for_media(mobileScreen) {
    font-size: 6.5vw !important;
  }

  @include for_media(tabletScreen) {
    font-size: 3.5vw !important;
  }
}

h4 {
  font-family: $primaryFontName !important;
  font-size: 1.5vw !important;
  font-weight: bold !important;

  @include for_media(mobileScreen) {
    font-size: 5vw !important;
  }

  @include for_media(tabletScreen) {
    font-size: 3.5vw !important;
  }
}


p {
  font-size: 1.3vw !important;
  @include for_media(mobileScreen) {
    font-size: 4vw !important;
  }
}

ul {
  font-size: 1.3vw !important;
  @include for_media(mobileScreen) {
    font-size: 4vw !important;
  }
}

a {
  color: #000 !important;
  text-decoration: underline;
}


.floatingIcons  {
  position: fixed;
	bottom: 2em;
  right: 0em;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
  img {
    width: 45%;
    height: auto;
    aspect-ratio: 1;
    margin-bottom: 0.5em;
    cursor: pointer;
  }
}