@import 'assets/scss/main.scss';

.header {
  background-color: $white;
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  align-items: center;
  @include for_media(mobileScreen tabletScreen) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: url('../../../../../assets/images/backgroundImage.webp') !important;
    z-index: 999;
  }

  picture{
    @include for_media(mobileScreen){
      width: 50%;
    }
  }

  .headerLogo {
    position: relative !important;
    top: 22% !important;
    left: 27% !important;
    width: 70% !important;
    height: auto !important;
    cursor: pointer !important;
    aspect-ratio: 2.66;
    mix-blend-mode: darken !important;
    @include for_media(mobileScreen) {
      position: relative !important;
      left: 6% !important;
    }
    @include for_media(tabletScreen){
      position: relative;
      left: 10% !important;
    }
  }
  
  
  .dotPattern1 {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    width: 12% !important;
    height: auto !important;
    @include for_media(mobileScreen) {
      display: none !important;
    }
  }
  
  .user {
    display: none !important;
    @include for_media(mobileScreen) {
      display: block !important;
      position: absolute ;
      top: 0.5em;
      right: 2.2em;
      font-size: 2em !important;
      z-index: 1000  !important;
      cursor: pointer;
    }
  }
  
  .signUpLoginWrapper {
    padding: 0 5em;
    @include for_media(mobileScreen tabletScreen){
      padding: 0 3.4em 0 0 !important;
      p{
        padding: 0.4em 1.2em !important;
      }
    }
    p{
      font-family: $secondaryFontName !important;
      border: 2px solid $grayColor3;
      border-radius: 45px;
      padding: 0.6em 3.2em;
      font-size: 1em !important;
      -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
       transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
      cursor: pointer;
      z-index: 21;
      position: relative;
      background-color: $white !important;
      @include for_media(tabletScreen mobileScreen){
        padding: 0.6em 1.6em;
      }
    }
    p:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 0 0 $cflLightTertiaryColor; 
    }

    
    
  
    .signup {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      cursor: pointer;
      /* identical to box height */
  
      color: #000000;
      
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .login {
  
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
  
      cursor: pointer;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
  
      /* identical to box height */
  
      color: #000000;
    }
  }
}

.bottomBorder{
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #000000, #9b9b9b3d, #ffffff);
  display: inline-block;
}

.headerSection{
  padding: 1em 0em 0em 0em;
  .logoSection{
      background: $white;
      display: flex;
      justify-content: space-between;
      padding: 0.5em 2em;
      align-items: center;
      .logo{
          cursor: pointer;
          max-width: 20vh;
      }
      .enrollBtn{
          background: $v2PrimaryVariant4;
          border-radius: 20px;
          color: $white;
          font-family: $primaryFontName;
          -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
         transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
      }
      .enrollBtn:hover{
       transform: translateY(-5px);
       box-shadow: 0 6px 0 0 $cflLightTertiaryColor; 
       background: $v2PrimaryVariant4;
       color: $white;
      }
      @include for_media(mobileScreen){
        padding: 0.5em 1em;
      }
  }
}