@import 'assets/scss/main.scss';

.trendingAssessContainer{
    width: 100%;
    text-align: center;
    .expanded {
        height: 380px !important;
        @include for_media(tabletScreen mobileScreen){
            height: 620px !important;
         }
      }
    .primaryContentWrapper{
        background-image: $darkBgTextureImg;
        padding: 2.5em;
        line-height: 1.5;
        color: $white;
        position: relative;
        height: 239px;
        overflow: hidden;
        transition: height 0.7S ease; 
        @include for_media(tabletScreen mobileScreen){
            padding: 1em;
            height: 350px;
         }
        .crownFace{
            position: absolute;
            bottom: 0;
            left: 0;
            @include for_media(tabletScreen mobileScreen){
                width: 5em;
                top: 0;
             }
        }
        .trendingAssessmentTxt{
            font-size: 1.8em;
            font-family: $secondaryFontName;
            line-height: 1.5;
            @include for_media(tabletScreen mobileScreen){
                font-size: 1.5em;
             }
        }
        .crownTxt{
            color: $v2PrimaryVariant3;
            text-align: center;
            font-family: $primaryFontName;
            font-size: 2.5em;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.5;
            @include for_media(tabletScreen mobileScreen){
                font-size: 1.8em;
             }
        }
        .crownDesc{
            font-family: $secondaryFontName;
            font-size: 1.2em;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.3px;  
            width: 70%;
            margin: 0.5em auto;
            @include for_media(tabletScreen mobileScreen){
                width: 100%;
                margin-top: 3em;
                font-size: 1.1em;
                text-align: justify;
             }
        }
        .readMoreTxt{
            display: flex;
            justify-content: flex-end;
            color: $v2PrimaryVariant3;
            cursor: pointer;
        }
        .extraText{
            margin: 1em 0em;
        }
    }
    .secondaryContentWrapper{
        background-image: $lightBgTextureImg;
        line-height: 1.5;
        position: relative;
        @include for_media(tabletScreen mobileScreen){
            background-size: 100% 100%;
            padding: 1.5em
         }
        .crownFooter{
            position: absolute;
            left: 2em;
            top: 0;
            z-index: 10;
            height: 100%;
            width: 7em;
            @include for_media(tabletScreen mobileScreen){
                width: 1em;
                left: 0;
                height: auto;
             }
        }
        .assessmentToggleSection{
            display: inline-flex;
            align-items: center;
            gap: 2em;
            justify-content: center;
            width: 100%;
            padding: 1em;
            @include for_media(tabletScreen mobileScreen){
                gap: 1em;
             }
            .defaultToggleBtn{
                color: $white;
                text-align: center;
                font-family: $primaryFontName;
                font-size: xx-large;
                font-weight: 700;
                cursor: pointer;
                padding: 0em 0.5em;
                @include for_media(tabletScreen mobileScreen){
                    font-size: 1.5em;
                 }
            }
            .divider{
                border: 1px solid $white;
                height: 30px;
                width: 0px;
            }
            .activeToggleBtn{
                color: $v2PrimaryVariant3;
                border-bottom: 2px solid; 
                border-image: linear-gradient(to right, $v2PrimaryVariant2 1%, $white 40%, $white 60%, $v2PrimaryVariant2 99%);
                border-image-slice: 1;
                position: relative;
                .activeDot{
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    background: $white;
                    position: absolute;
                    right: 0;
                    top: 0;
                }    
            }
        }
        .btnWrapper{
            display: flex;
            gap: 1em;
            justify-content: center;
            margin: 2em auto;
            width: 80%;
            @include for_media(tabletScreen mobileScreen){
                flex-direction: column;
                align-items: center;
                gap: 1.5em;
                width: 100%;
              }
            .defaultBtn{
                border-radius: 40px;
                background-color: $white;
                font-family: $secondaryFontName;
                padding: 0.5em 3em;
                font-size: 1.2em;
                min-width: 200px;
                cursor: pointer;
                font-weight: 400;
                text-transform: uppercase;
                border: 1px solid $black;
                -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
                transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);

                @include for_media(tabletScreen mobileScreen){
                   font-size: 1em;
                  }
            }
            .defaultBtn:hover{
                transform: translateY(-5px);
                box-shadow: 0 4px 0 0 $cflLightPrimaryColor;  
            }
            .activeBtn{
                background-color: $v2PrimaryVariant3;
                color: $white;
                -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
                transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
            }
            .activeBtn:hover{
                transform: translateY(-5px);
                box-shadow: 0 4px 0 0 $cflLightPrimaryColor;  
            }
        }
        .secondaryDesc{
            font-family: $secondaryFontName;
            font-size: 1.2em;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.3px;  
            width: 70%;
            margin: 0.5em auto;
            color: $white;
            @include for_media(tabletScreen mobileScreen){
                width: 100%;
                text-align: justify;
              }
        }
        .skillWrapper{
            display: flex;
            gap: 2em;
            align-items: flex-start;
            margin: 2em 0 0 0;
            @include for_media(tabletScreen mobileScreen){
                flex-direction: column;
                gap: 4em;
              }
            .skillImageWrapper{
                width: 50%;
                position: relative;
                z-index: 50 !important;
                @include for_media(tabletScreen mobileScreen){
                    width: 100%;
                  }
                img{
                    width: 100%;
                    height: 100%;
                    border: 30px solid $black;
                    border-left: 8px solid $black;
                    cursor: pointer;
                    @include for_media(tabletScreen mobileScreen){
                        border: 10px solid $black;
                        border-left: 8px solid $black;
                        border-left: 3px solid $black;
                      }
                }
                .zoomTxt{
                    display: flex;
                    justify-content: flex-end;
                    color: $white;
                    gap: 0.5em;
                    font-family: $secondaryFontName;
                    cursor: pointer;
                }
            }
            .expandedskillContent{
                height: 850px !important;
                @include for_media(tabletScreen mobileScreen){
                    height: 1400px !important;
                  }
            }
            .skillContentWrapper{
                height: 72vh;
                overflow: hidden;
                transition: height 0.7S ease; 
                width: 50%;
                text-align: left;
                color: $white;
                padding: 1.5em;
                line-height: 1.5;
                font-family: $secondaryFontName;
                background: $crownSkillGradientColor;
                border-left: 3px solid;
                border-top: 3px solid;
                border-image: 
                linear-gradient(to right bottom, $white 20%, $v2PrimaryVariant2 30%) 1;
                @include for_media(tabletScreen mobileScreen){
                    width: 100%;
                  }
                .heading{
                    font-size: x-large;
                    color: $v2PrimaryVariant3;
                    font-family: $secondaryFontName;
                    text-transform: uppercase;
                    font-weight: 700;
                }
                .content{
                    font-family: $secondaryFontName;
                    text-align: justify;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .readMoreTxt{
                    color: $v2PrimaryVariant3;
                    cursor: pointer;
                    font-size: 18px;
                    margin: 0.5em 0em;
                    font-family: $secondaryFontName;
                }
                .forTxt{
                    font-size: 18px;
                    margin: 0.5em 0em;
                    font-family: $secondaryFontName;
                    text-transform: capitalize;
                }
                .exampleWrapper{
                    margin-top: 1em;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 1em;
                    img{
                        width: 2.5em;
                    }
                    @include for_media(tabletScreen mobileScreen){
                        align-items: flex-start;
                      }
                }
                .secondExampleWrapper{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 1em;
                    margin-left: 3.5em;
                    img{
                        width: 2.5em;
                    }
                    @include for_media(tabletScreen mobileScreen){
                        align-items: flex-start;
                      }
                }
                .requestBtn{
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 2em;
                    position: relative;
                    z-index: 2;
                    .button{
                        padding: 0.5em 2em;
                        font-family: $secondaryFontName;
                        background-color: $white;
                        color: $black;
                        border-radius: 40px;
                        border: 1px solid $black;
                        -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
                         transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
                        cursor: pointer;
                        i{
                            margin-left: 0.5em;
                        }
                    }
                    .button:hover{
                        transform: translateY(-5px);
                        box-shadow: 0 4px 0 0 $cflLightPrimaryColor;
                    }
                }
            }
        }
        .activeAssessmentTxtWrapper{
            font-size: 9em;
            text-transform: capitalize;
            opacity: 0.1;
            color: $white;
            font-family: $secondaryFontName;
            font-style: normal;
            font-weight: 700;
            position: absolute;
            right: 0;
            bottom: 0.6em;
            line-height: 0;
            z-index: 0;
            -webkit-text-stroke: 6px;
            @include for_media(tabletScreen mobileScreen){
                font-size: 5em;
                -webkit-text-stroke: 3px;
              }
        }
    }
}

.modalImg{
    width: 100%;
}
.closingContainer{
    cursor: pointer;
    position: absolute !important;
    right: 0;
}