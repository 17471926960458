@import 'assets/scss/main.scss';

.modalContentWrapper{
    background: $white;
    color: $black;
    border-radius: 3px;
    font-family: $secondaryFontName;
    display: flex;
    gap: 2em;
    @include for_media(mobileScreen){
        flex-direction: column;
        gap: 0em;
    }
    .rightSection{
        padding: 2em 0em;
        width: 100%;
        .headerSection{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include for_media(mobileScreen){
                padding: 0 1em;
             }
            svg{
                width: 60%;
                height: 100%;
                cursor: pointer;
                @include for_media(mobileScreen){
                   display: none;
                 }
            }
            .header{
                color: $black;
                font-family: $secondaryFontName;
                font-size: 2em;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                @include for_media(mobileScreen){
                    font-size: 1.4em;
                 }
            }
            .leaveMsg{
                color: $black;
                font-family: $secondaryFontName;
                font-size: 1em;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include for_media(mobileScreen){
                    font-size: small;
                 }
            }
        }
    }
    .responsiveFrameWrapper{
        display: flex;
        width: 100%;
        height: 50px;
        .frame{
            width: 100%;
        }
        .closeIconSvg{
            width: 20%;
            background: $black;
            padding: .2em;
            cursor: pointer;
            svg{
                width: 100%;
                height: 100%;
            }
        }
    }
}