@import 'assets/scss/main.scss';

.trendingProgContainer{
    width: 100%;
    text-align: center;
    .primaryContentWrapper{
        position: relative;
        background-image: $darkBgTextureImg;
        padding: 2.5em;
        line-height: 1.5;
        color: $white;
        @include for_media(tabletScreen mobileScreen){
           padding: 1em;
        }
        .trendingProgramTxt{
            font-size: 1.8em;
            font-family: $secondaryFontName;
            line-height: 1.5;
            @include for_media(tabletScreen mobileScreen){
                font-size: 1.5em;
             }
        }
        .iflTxt{
            color: $v2PrimaryVariant3;
            text-align: center;
            font-family: $primaryFontName;
            font-size: 2.5em;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.5;
            @include for_media(tabletScreen mobileScreen){
                font-size: 1.8em;
             }
        }
        .iflDesc{
            font-family: $secondaryFontName;
            font-size: 1.1em;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.3px;  
            width: 75%;
            margin: 0.5em auto;
            @include for_media(tabletScreen mobileScreen){
                width: 100%;
                text-align: justify;
             }
        }
        .statisticWrapper{
            position: relative;
            display: flex;
            align-items: center;
            margin: 2em 9em 1em 9em;
            @include for_media(tabletScreen mobileScreen){
                margin: 0em;
                flex-wrap: wrap;
             }
            .statisticPt{
                max-width: 280px;
                padding: 0 1em;
                font-family: $secondaryFontName;
                font-size: 0.95em;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.24px;
                span{
                    color: $v2PrimaryVariant3;
                }
                @include for_media(tabletScreen mobileScreen){
                    flex-basis: calc(50% - 10px);
                    padding: 1em;
                    max-width: fit-content;
                 }
            }
            .divider{
                border: 1px solid $v2PrimaryVariant3;
                height: 25px;
                width: 0;
            }
            .imgWrapper{
                margin: auto;
                img{
                    width: 8em;
                } 
            }
        }
        .womenFace{
            img{
                position: absolute;
                right: 0; 
                bottom: 0;
                width: 13vw;
                @include for_media(bigTabletScreen tabletScreen mobileScreen){
                  display: none;
                 }
            }
        }
    }
    .secondaryContentWrapper{
        background-image: $lightBgTextureImg;
        padding: 1.5em;
        line-height: 1.5;
        position: relative;
        .btnWrapper{
            display: flex;
            gap: 1em;
            justify-content: center;
            @include for_media(tabletScreen mobileScreen){
                flex-direction: column;
                align-items: center;
                gap: 1.5em;
              }
          .defaultBtn {
                border-radius: 40px;
                background-color: $white;
                font-family: $secondaryFontName;
                padding: 0.5em 3em;
                font-size: 1.2em;
                min-width: 200px;
                -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
                transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
                cursor: pointer;
                font-weight: 400;
                border: 1px solid $black;            
                @include for_media(tabletScreen mobileScreen) {
                    font-size: 1em;
                }
            }
            
            .defaultBtn:hover {
                transform: translateY(-5px);
                box-shadow: 0 4px 0 0 $cflLightPrimaryColor;  
            }
            
            .activeBtn{
                -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
                transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
                background-color: $v2PrimaryVariant3;
                color: $white;
            }
            .activeBtn:hover{
                transform: translateY(-5px);
            }

        }
        .secondaryDesc{
            font-family: $secondaryFontName;
            font-size: 1.2em;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.3px;  
            width: 70%;
            margin: 1.5em auto;
            color: $white;
            @include for_media(tabletScreen mobileScreen){
                width: 100%;
                text-align: justify;
              }
        }
        .programContainer{
            margin-bottom: 2em;
            background-color: $white !important;
            box-shadow: 2em 2em $cflLightSecondaryColor;
            padding: 1.5em;
            width: 90%;
            @include for_media(tabletScreen mobileScreen){
                width: 100%;
                margin: 0 !important;
                padding: 0;
                box-shadow: 1em 1em $cflLightSecondaryColor;
              }
            .programColumn{
                margin: 0.5em;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url("../../../../../assets/images/v2/trending/programBg.webp");
                .heading{
                    text-align: center;
                    font-family: $secondaryFontName;
                    font-size: 1.1em;
                    font-weight: 700;
                    padding-bottom: 1em;
                    line-height: 1.2;
                    min-height: 95px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include for_media(tabletScreen mobileScreen){
                        min-height: fit-content;
                        text-align: left;
                        font-size: 0.9em;
                        padding-bottom: 0.5em;
                        line-height: normal;
                    }
                }
                .content{
                    color: $black;
                    text-align: center;
                    font-size: 1em;
                    font-weight: 400;
                    min-height: 130px;
                    font-family: $secondaryFontName;
                    @include for_media(tabletScreen mobileScreen){
                        min-height: fit-content;
                        text-align: left;
                        font-size: 0.8em;
                    }
                }
                .commingSoonTxt{
                    color: $black;
                    font-family: $secondaryFontName;
                    font-size: 1.2em;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    min-height: 150px;
                    line-height: 150px;
                    vertical-align: middle !important;
                }
                img{
                    margin: 1em auto;
                    height: 280px;
                    width: auto;
                    @include for_media(tabletScreen mobileScreen){
                        height: auto;
                        margin: 0;
                        width: 100%;
                    }
                }
                .certificationTxt{
                    text-align: center;
                    font-family: $secondaryFontName;
                    font-size: 1.2em;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 0.5em;
                    @include for_media(tabletScreen mobileScreen){
                        font-size: 1em;
                        margin: 1em auto;
                        text-align: left;
                    } 
                }
                @include for_media(tabletScreen mobileScreen) {
                    margin: 0.3em;
                }
            }
            .knowMoreTxt{
                display: flex;
                justify-content: flex-end;
                margin: 0.5em 0em;
                color: $v2PrimaryVariant3;
                font-family: $secondaryFontName;
                font-size: 1.5em;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0.48px;
                cursor: pointer;
                @include for_media(tabletScreen mobileScreen) {
                    font-size: 1.2em;
                    letter-spacing: 0px;
                    margin: 1em 0em;
                }
            }
        }
    }
    .requestBtn{
        display: flex;
        justify-content: center;
        padding-top: 4em;
        .button{
            padding: 0.7em 2em;
            font-family: $secondaryFontName;
            background-color: $white;
            border-radius: 40px;
            cursor: pointer;
            z-index: 100;
            border: 1px solid $black;
            i{
                margin-left: 0.5em;
            }
            -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
            transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
        }
        .button:hover{
            transform: translateY(-5px);
            box-shadow: 0 4px 0 0 $cflLightPrimaryColor;
        }
    }
    .activeProgramTxt{
        font-size: 9em;
        text-transform: capitalize;
        opacity: 0.1;
        color: $white;
        font-family: $secondaryFontName;
        font-style: normal;
        font-weight: 700;
        position: absolute;
        right: 0;
        bottom: 0.5em;
        line-height: 0;
        -webkit-text-stroke: 6px;
        @include for_media(tabletScreen mobileScreen){
            font-size: 4em;
            bottom: 1.5em;
            -webkit-text-stroke: 3px;
          }
    }
}