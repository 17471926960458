@import 'assets/scss/main.scss';

.homeScreenWrapper {
  margin: -0.5em 0 0 0 !important;
  width: auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  overflow: hidden !important;

  .creativeBannerImage {
    width: 53vw !important;
    height: 100% !important;    
  }

  .descWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 13.2em;
    letter-spacing: 1px;
    @include for_media(tabletScreen mobileScreen) {
      height: auto !important;
      padding: 1em 0;
    }
    .evokeText {
      text-align: center;
      color: $v2PrimaryVariant3;
      font-family: $secondaryFontName !important;
      font-weight: 700 !important;
      -webkit-text-stroke: 1px;
      @include for_media(tabletScreen mobileScreen) {
        font-size: 1.5em !important;
        padding: 0em 0.5em;
      }
    }


    .desc {
      font-family: $secondaryFontName !important;
      width: 66%;
      color: $white ;
      text-align: center;
      font-size: 1.1em !important;
      @include for_media(tabletScreen mobileScreen) {
        text-align: justify;
        padding: 0 1em;
        margin-top: 0;
      }
    }
  }

  .contactUsWrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    svg {
      width: 50%;
      height: 100%;
      margin: 5em;
      margin-bottom: 10em !important;
    }
  
    .leafPattern9 {
      position: absolute !important;
      bottom: 0 !important;
      right: -3% !important;
      width: 30% !important;
      height: 100% !important;
    }
  } 
  
  /*       Mobile Screen           */

  @include for_media(tabletScreen mobileScreen) {
    margin: 1em 0 0 0 !important;
    .creativeBannerImage {
      width: 100% !important;
      padding: 0 2.5em;
    }

    .descWrapper {
      h3 {
        margin-top: 0.5em;
      }
  
      .desc {
        width: 90%;
        margin-top: 0.5em;
      }
    }

    .contactUsWrapper {
      svg {
        width: 90%;
        height: 100%;
        margin: 2em;
        margin-bottom: 5em !important;
      }

      .leafPattern9 {
        width: 55%;
        height: 35% !important;
      }
  
    }
  }
}
.bannerAndDescWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('../../../../assets/images/v2/homeBg.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @include for_media(tabletScreen mobileScreen) {
    background-image: url('../../../../assets/images/v2/mobileBackground.webp');
  }
}
.demoButton{
  border-radius: 45px;
  padding: 0.8em 3em;
  border: none;
  font-family: $secondaryFontName !important;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
   transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
  cursor: pointer;
  color: $black;
   @include for_media(tabletScreen mobileScreen) {
   padding: 0.6em 2.5em;
   font-size: 1.2em;
  }
}
.demoButton:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 0px -1px $cflLightPrimaryColor;
  border: 1px solid;
}

.leafConatiner{
  position: absolute;
  right: -4%;
  transform: rotate(180deg);
  top: 13%;
  z-index: -1;
  img{
    width: 75%;
  }
  @include for_media(tabletScreen mobileScreen) {
    display: none !important;
  }
}
.clr4{
  width: 100%;
  height: 5em;
  background: linear-gradient(to right, $grayColor1 1%, $white 8%, $white 92%, $grayColor1 99%);
  display: flex;
  justify-content: flex-end;
  @include for_media(tabletScreen mobileScreen){
    height: 2.5em;
  }
  img{
    position: relative;
    right: 0;
    bottom: 0em;
    mix-blend-mode: darken;
  }
}