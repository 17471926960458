@import 'assets/scss/main.scss';

.container{
    background-color: $white;
    width: 100%;
    position: relative;
}
.sliderContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
    @include for_media(tabletScreen mobileScreen) {
      align-items: unset;
   }
  }

  .sliderBtn{
    height: 260px;
    position: relative;
    max-height: 100%;
    width: 9%;
    background: linear-gradient(to right, $white, $grayColor1); 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include for_media(tabletScreen mobileScreen) {
        svg{
            width: 45%;
        }
     }
  }
  .sliderBtnLeft{
    position: relative;
    height: 260px; 
    max-height: 100%;
    width: 7%;
    background: linear-gradient(to right, $grayColor1, $white); 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include for_media(tabletScreen mobileScreen) {
        svg{
            width: 60%;
        }
     }
  }

  .disabled{
    position: relative;
    height: 260px; 
    top: 1em;
    max-height: 100%;
    width: 7%;
    cursor: default;
    background: none;
  }

  .line{
    display: inline-block;
    border: 1px solid $black;
    width: 190px;
    @include for_media(tabletScreen mobileScreen){
      width: 70%;
    }
  }
  .smallLine{
    display: inline-block;
    border: 1px solid $v2PrimaryVariant1;
    width: 10px;
    margin: 0 1em;
  }
  .titleWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8%;
    margin: 2em 0;

    h4{
        margin: 0;
        text-align: center;
        font-family: $primaryFontName !important;
        font-size: 2vw !important;
        cursor: pointer;
        font-weight: 100 !important;
        @include for_media(tabletScreen mobileScreen) {
        font-size: 4.5vw !important;
        position: relative;
        }
    }
    .activeDot{
        width: 10px;
        display: inline-block;
        height: 10px;
        background-color: $v2PrimaryVariant1;
        border-radius: 21px;
        position: relative;
        left: 3%;
        bottom: 23px;
      }
      @include for_media(tabletScreen mobileScreen) {
        width: 100%;
        gap: 0;
        margin: 2em 0 1em 0em;
        padding: 0em 0.4em;
       }
    .menuContainer{
        @include for_media(tabletScreen mobileScreen) {
            width: 50%;
      }
    }
  }
  .description{
    text-align: center;
    font-family: $secondaryFontName !important;
    @include for_media(tabletScreen mobileScreen) {
     font-size: 0.9em !important;
     padding: 0em 1em;
    }
}

.gradientLine{
    display: inline-block;
    background: linear-gradient(to right, $white, $grayColor2, $white);
    width: 420px;
    height: 2px; 
    @include for_media(tabletScreen mobileScreen){
    width: 100%;
    }
}
  .hideLine{
    opacity: 0;
  }
.activeContent{
    h4{
     color: $v2PrimaryVariant3;
     font-weight: 800 !important;
    }
}

.profession{
    font-family: $secondaryFontName !important;
    font-size: 1vw !important;
    margin: 0 0 2px 0;
    @include for_media(tabletScreen mobileScreen){
      font-size: 0.8em !important;
   } 
}

.leafPattern{
    position: absolute;
    bottom: -1%;
    img{
      width: 73%;
    }
    @include for_media(tabletScreen mobileScreen){
        height: auto !important;
        bottom: 8%;
        img{
          width: 52%;
        }
     } 
}

.backgroundText{
  width: 83%;
  float: inline-end;
  position: relative;
  bottom: 1em;
   svg{
    width: 75%;
    float: right;
    height: 100%;
   }
   @include for_media(tabletScreen mobileScreen){
       margin-top: 4%;
       h1{
           font-size: 11vw !important;
       }
   }
}
.fortImageWrapper{
  height: 10em;
  .fortImage{
    position: absolute;
    right: 2%;
    bottom: 6%;
  }
  @include for_media(tabletScreen mobileScreen){
    display: none;
  }
}


  .studentcontentWrapper{
     position: relative;
     height: 300px;
     background-color: $white;
     .contentTopBorder::before,
     .contentTopBorder::after,
     .contentBottomBorder::before,
     .contentBottomBorder::after
     {
       content: "";
       position: absolute;
       display: inline-block;
       height: 3px;
       background: linear-gradient(to right, $v2PrimaryVariant1, $v2PrimaryVariant3, $white);
     }
     .contentTopBorder::before{
       background: linear-gradient(to right, $v2PrimaryVariant1, $v2PrimaryVariant3, $white);
       width: 242px;
        left: 0px;
     }
     .contentTopBorder::after{
       background: linear-gradient(to right, $v2PrimaryVariant1, $v2PrimaryVariant3, $white);
       width: 222px;
       left: -109px;
       top: 37%;
       transform: rotate(90deg);
     }
     .contentBottomBorder::before{
       background: linear-gradient(to right, $white, $v2PrimaryVariant3, $v2PrimaryVariant1);
       width: 251px;
       right: 0px;
       top: 82%;
       @include for_media(tabletScreen mobileScreen){
        top: 92%;
      }
     }
     .contentBottomBorder::after{
       background: linear-gradient(to right, $white, $v2PrimaryVariant3, $v2PrimaryVariant1);
       width: 273px;
       right: -134px;
       top: 37%;   
       transform: rotate(90deg);
       @include for_media(tabletScreen mobileScreen){
        top: 47%;
      }
     }
  }

  .image-container {
    height: 250px;
    width: 83%;
    overflow: hidden;
    @include for_media(tabletScreen mobileScreen){
      height: 395px !important;
     }
  }
  .studentContainer{
    @include for_media(tabletScreen mobileScreen){
      height: 300px !important;
     }
  }
  .image-container .carousel {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .addAnimation{
    transition: all 0.4s ease;
  }
  .carousel img {
    border-radius: 18px;
    object-fit: cover;
    padding: 1em;
  }
  
  .carousel{
    transition: "transform 0.5s ease-in-out";
  }

  .singleContent{
    margin: 0em 28%;
  }

  .EmployeesContent{
    display: flex;
    width: 50%;
    @include for_media(tabletScreen mobileScreen){
      width: 100% !important;
     }
    @include for_media(bigtabletScreen mobileScreen){
     width: 100% !important;
    }
    .cardContent {
        width: 1500px;
        font-family: $secondaryFontName !important;
        padding: 1em 0em;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4{
          margin: 0;
          font-family: $secondaryFontName !important;    
          font-size: 1.1vw !important;
          color: $v2PrimaryVariant1;
          @include for_media(tabletScreen mobileScreen){
            font-size: 1.1em !important;
          }
        }
        p{
            font-size: 0.8vw !important;
            line-height: 1.4;
            @include for_media(tabletScreen mobileScreen){
              font-size: 0.8em !important;
            }
        }
        .userName{
            font-family: $secondaryFontName !important;
            font-size: 1.3vw !important;
            margin: 0 0 2px 0;
            @include for_media(tabletScreen mobileScreen){
              font-size: 1.3em !important;
            }
        }
        @include for_media(tabletScreen mobileScreen){
          padding: 0;
          justify-content: unset;
         }
    }
    .imageContainer{
      img{
        width: 14em;
        @include for_media(tabletScreen mobileScreen){
          width: 10em !important;
         }
      }
    }
  }
.studentcontentWrapper{
  .studentContent{
    justify-content: normal !important;
    width: 1500px;
    padding: 2em;
    @include for_media(tabletScreen mobileScreen){
      padding: 1em;
     }
    h2{
        margin-top: 1em !important;
    }
  }
}

@include for_media(tabletScreen mobileScreen){
 
  .cardContent::before,
  p.cardContent::after {
    content: '';
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 0;
  }

  .cardContent::before {
    margin-bottom: 10px; 
  }

  .cardContent::after {
    margin-top: -10px; 
    float: left;
    width: 50%;
  }
}