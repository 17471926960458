
/* Primary Font */

@font-face {
  font-family: "Book Antiqua";
  src: local("Book_Antiqua_Bold"),
    url("../fonts/Book_Antiqua_Bold.ttf") format("truetype");
  font-weight: bold;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Book Antiqua";
  src: local("Book_Antiqua_Regular"),
    url("../fonts/Book_Antiqua_Regular.ttf") format("truetype");
  font-weight: normal;
  font-weight: 400;
  font-display: swap;
}


@font-face {
    font-family: "Brush Script MT";
    src: local("BRUSHSCI"),
      url("../fonts/BRUSHSCI.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Poppins";
    src: local("poppins"),
      url("../fonts/poppins.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Poppins";
    src: local("Poppins-Medium"),
      url("../fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
  }

  @font-face {
    font-family: "Josefin Sans";
    src: local("JosefinSans_ExtraLight"),
      url("../fonts/JosefinSans-ExtraLight.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Della Respira";
    src: local("DellaRespira-Regular"),
      url("../fonts/DellaRespira-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
  }