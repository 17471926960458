@import 'assets/scss/main.scss';

    .typeSelectSection{
        display: flex;
        margin: 1em;
        justify-content: space-evenly;
        align-items: center;
        @include for_media(mobileScreen){
            flex-wrap: wrap;
            margin: 0 0 2em 0;
         }
        .defaultBtn{
            text-align: center;
            font-family: $secondaryFontName;
            font-size: 1.4em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
            @include for_media(mobileScreen){
                font-size: 1.2em;
                padding: 0.5em;
                width: 45%;
             }
        }
        .activeBtn{
            color: $v2PrimaryVariant1;
            border-bottom: 2px solid; 
            border-image: linear-gradient(to right, $white 1%, $v2PrimaryVariant2 40%, $v2PrimaryVariant2 60%, $white 99%);
            border-image-slice: 1;
        }
    }
    .formWrapper{
        padding: 1em 2em;
        input{
            border-radius: 0 !important;
            border-bottom: 1px solid $black !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
        }
        ::placeholder {
            color: $v2PrimaryVariant1 !important;
            opacity: 1;
        }
        .submitBtn{
            background-color: $white !important;
            border: 1px solid $black !important;
            padding: .8em 3em;
            color: $black !important;
            border-radius: 25px !important;
            font-family: $secondaryFontName;
            font-size: 1.1em !important;
            i{
                color: $black;
            }
            -webkit-transition: -webkit-transform 0.3s cubic-bezier(.5, 2.5, .7, .7), -webkit-box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
            transition: transform 0.3s cubic-bezier(.5, 2.5, .7, .7), box-shadow 0.3s cubic-bezier(.5, 2.5, .7, .7);
        }
        .submitBtn:hover{
            transform: translateY(-5px);
            box-shadow: 0 5px 0 0 $v2PrimaryVariant1 !important;
        }

        .radioWrapper{
            margin-bottom: 1.5em;
            label{
                color: $v2PrimaryVariant1 !important;
            }
        }
    }
    .formField{
        margin: 0 -0.5em 2.7em !important;
    }
    .radioBtn{
        label::after{
            background-color: $v2PrimaryVariant1 !important;
        }
    }
    .redioBtnWrapper{
        padding: 0 0.7em !important;
    }
