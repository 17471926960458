@import 'assets/scss/main.scss';

.header {
  margin-top: 2em;
  @include for_media(tabletScreen mobileScreen) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: url('../../../../../assets/images/backgroundImage.webp') !important;
    z-index: 999;
  }

  .headerWrapper {
    margin: 0 !important;
    width: auto !important;
  
    svg {
      height: 95%;
      width: 100%;
      @include for_media(tabletScreen mobileScreen) {
        display: none;
      }
    }
  
    .tabWrapper {
      display: flex;
      justify-content: center;
      align-items: baseline;
      margin-top: 0;
      margin-bottom: -15px;
      position: relative;
      z-index: 5;
  
      .tabItemWrapper {
        position: relative;
        cursor: pointer;
  
        img {
          width: auto !important;
          height: auto !important;
        }

        .notificationIcon {
          top: 0px;
          position: absolute;
          left: 70px;
        }
  
        .name {
          font-family: "Poppins";
          font-weight: bold;
          position: absolute;
          top: 48%;
          left: 56%;
          transform: translate(-50%, -50%);
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          width: 70px;
          text-align: center;
          color: white;
        }
      }
    }
  }
  
  .barIconOpen {
    display: none !important;
    @include for_media(mobileScreen) {
      display: block !important;
      position: fixed ;
      top: 3.5%;
      right: 3.8%;
      font-size: 1.8em !important;
      z-index: 1002  !important;
    }
    @include for_media(tabletScreen){
      display: block !important;
      position: fixed ;
      top: 3.8%;
      right: 2%;
      font-size: 1.8em !important;
      z-index: 1002  !important;
    }
  }
  
  .barIconClose {
    display: none !important;
    @include for_media(mobileScreen) {
      display: block !important;
      position: fixed ;
      top: 3.2%;
      right: 3.8%;
      font-size: 1.8em !important;
      z-index: 1002  !important;
    }
    @include for_media(tabletScreen){
      display: block !important;
      position: fixed ;
      top: 3.8%;
      right: 2%;
      font-size: 1.8em !important;
      z-index: 1002  !important;;
    }
  }

}


.menuWrapper {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  right: 0  !important;
  position: fixed  !important;
  top: 8.5em !important;
  z-index: 999  !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden !important;  
  background: rgba(255, 255, 255, 0.97) !important;
  z-index: 9999 !important;

  .menu {
    box-shadow: none !important;
    border: none !important;
    .menuItem {
      font-family: "Poppins";
      font-weight: bold;
      position: absolute;
      font-style: normal;
      font-weight: 400;
      font-size: 4vw;
      color: #5f5e5e;
    }

    .aciveMenuItem {
      background: linear-gradient(87.97deg, #E8D7DB 0%, rgba(255, 231, 236, 0.81) 100%) !important; 
    }
  }

  svg {
    width: 100%;
    margin-top: 5em;
  }
}