@import 'assets/scss/main.scss';

.footerTopLayer{
  background: $footerTopLayerColor;
  height: 2em;
}
.footerWrapper {
  position: relative;
  background-image: $darkBgTextureImg;
  padding: 1em 0 0.5em 0;
  overflow: hidden;

  a{
    color: $white !important;
  }
  .addressContent{
    padding: 1em 4em !important;
    text-align: left  !important;
    @include for_media(tabletScreen mobileScreen) {
      width: 50% !important;
      padding: 0 1em !important;
  }
}
.footerImgWrapper{
  @include for_media(tabletScreen mobileScreen) {
    display: none !important;
  }
}
  
  .footer {
    margin: 0 !important;
    flex-wrap: nowrap !important;
    width: 85%;
    @include for_media(tabletScreen mobileScreen) {
      flex-wrap: wrap !important;
      width: 100%;
      overflow: hidden;
    }
    .header {
      font-family: $secondaryFontName;
      font-style: normal;
      font-weight: 700;
      font-size: 1.3vw;
      margin-bottom: 0.5em;
      color: $white;
      @include for_media(tabletScreen mobileScreen) {
        font-size: 4.4vw !important;
      }
    }

    .content {
      font-family: $secondaryFontName;
      font-style: normal;
      font-weight: 400;
      font-size: 1.15vw;
      margin-top: 5px;
      line-height: 1.7;
      color: $white;
      
      .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        @include for_media(tabletScreen mobileScreen) {
          gap: 10px;
         img{
          width: 33px;
         }
        }
      }
      
      .viewBlog {
        display: flex;
        justify-content: center;
        text-decoration-line: underline;
      }
      @include for_media(tabletScreen mobileScreen) {
        font-size: 3vw !important;
        line-height: 1.4;
      }
    }
  }
  .contactContent{
    @include for_media(tabletScreen mobileScreen) {
      width: 50% !important;
      padding: 0 1em !important;
    }
  }

  .returnText {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding: 1em;
    font-family: $primaryFontName;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    cursor: pointer;


    color: $white;
  }


  .footerLeafImage {
    position: absolute;
    right: 2em;
    top: 0;
    width: 17% !important;
    transform: rotate(113deg);
    @include for_media(tabletScreen mobileScreen) {
      top: unset;
      bottom: -5em;
      width: 29% !important;
    }
  }
}
.footerImageforMobile{
  display: none !important;
  @include for_media(tabletScreen mobileScreen) {
    display: block !important;
    position: absolute;
    width: 27%;
    bottom: 0;
  }
}