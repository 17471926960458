@import 'assets/scss/main.scss';

.videoContainer{
    padding-top: 43.78% !important;
    background-color: $v2PrimaryVariant2 !important;
    width: 97% !important;
 }
 .videoCoverFrame{
    width: 100%;
    position: relative;
    overflow: hidden;

    .frameImageWrapper{
        width: 100%;
        img{
            width: 96%;
            height: 100%;
            position: absolute;
            @include for_media(mobileScreen){
                width: 98%;
            }
        }
    }
    .videoFrameWrapper{
        width: 100%;
        padding-bottom: 1.1vw;
        .videoCommonBg{
            width: 100%;
            z-index: 10;
        }
        // @include for_media(mobileScreen){
        //     padding-bottom: 8px;
        // }
    }
    .coverImage{
        height: 93%;
        position: absolute;
        z-index: 20;
        width: 40.5%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        img{
            float: right;
            width: 50%;
        }
        @include for_media(mobileScreen){
            height: 97%;
        }
    }
    @include for_media(mobileScreen){
        max-width: 380px;
        max-height: 166px;
    }
 }
 .videoFrame{
   width: 93%;
   height: 100%;
   @include for_media(mobileScreen){
    width: 95%;
}
 }

 .videoInfoWrapper{
    height: 88%;
    position: absolute;
    z-index: 45;
    width: 47%;
    left: 42%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    padding: 1em 0;
    gap: 1em;
 }
 .playIconWrapper{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    svg{
        width: 33px !important;
        height: auto !important;
    }
    p{
        color: $white;
        font-size: 1em !important;
        font-family: $secondaryFontName;
    }
    @include for_media(mobileScreen){
        p{
            font-size: 0.8em !important; 
        }
        svg{
            width: 20px !important;
        }
    }
 }
.videoPlayerWrapper{
    position: absolute;
    width: 96%;
    height: 100%;
    transition: z-index 10s ease;
    @include for_media(mobileScreen){
        width: 98%;
    }
}
.isPlaying{
    z-index: 100;
}

.hideImage {
    transform: translateX(137%);
    transition: transform 1s ease;
}
.visibleImage{
    transform: translateX(0%);
    transition: transform 1s ease;
    z-index: 150;
    position: relative;
}
.coverText{
    color: $v2PrimaryVariant3;
    p{
        font-size: 1.6em !important;
        font-family: $secondaryFontName;
        width: 100%;
        height: 100%;
        margin: 0;
        @include for_media(mobileScreen){
            font-size: 1.1em !important;
        }
    }
    font-weight: 900;
    -webkit-text-stroke: 1.3px $v2PrimaryVariant3;
    @include for_media(mobileScreen){
        -webkit-text-stroke: 1px;
    }
}