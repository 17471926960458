.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

:root {
  --color-loader: #849398;
  --loader-size: 100px;
  --loader-speed: 600ms;
}

.loader {
  --loader-height: calc(var(--loader-size) / 5);
  display: flex;

  position: relative;
}
.loader::before {
  content: "";
  position: absolute;
}

.loader::before,
.loader > span {
  display: block;
  width: var(--loader-height);
  height: var(--loader-height);
  border-radius: 50%;
  background: var(--color-loader);

  animation-name: to-right;
  animation-duration: var(--loader-speed);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.loader > span:nth-child(even) {
  visibility: hidden;
}
.loader > span:first-child {
  animation-name: scale-up;
}
.loader > span:last-child {
  animation-name: scale-up;
  animation-direction: reverse;
}

@keyframes to-right {
  to {
    transform: translateX(200%);
  }
}
@keyframes scale-up {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}